<template>
  <div>
    <b-overlay :show="show" no-wrap/>
    <b-row>
      <b-col cols="12" lg="3">
        <b-button
            @click="newConsultations"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Consulta
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item
                style="cursor: pointer"
                v-for="(a, idx) in consults"
                :key="idx"
                @click="viewConsultation(a.id)"
            >
              {{ resHoursOdooFormat(a.createdAt) }}(ver archivo) <br/>
              <span v-if="a.HrEmployee">{{ a.HrEmployee.name }}</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="12" lg="9" v-if="createConsultations">
        <h3 style="text-align: center">Consulta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input
                  readonly=""
                  v-model="consul.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6"></b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">Dia de consulta</h5>
              <flat-pickr
                  v-model="consul.date_consultation"
                  class="form-control"
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febreo',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%"/>
            <b-form-textarea
                id="textarea-default"
                rows="2"
                max-rows="999999"
                v-model="consul.note_evolution"
                placeholder="Nota de Evolución"
            />
          </b-col>
          <!-- <b-col cols="12" class="mt-2">
            <h4>Signos Vitales y Somatometría</h4>
            <hr style="width: 100%"/>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Altura(Mts)">
              <b-form-input
                  v-model="consul.height"
                  placeholder="Altura(Mts)"
                  @keyup="calculateImc"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Peso">
              <b-form-input
                  v-model="consul.weight"
                  @keyup="calculateImc"
                  placeholder="Peso"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="IMC">
              <b-form-input
                  v-model="consul.imc"
                  @click="calculateImc"
                  placeholder="IMC"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Superficie Corporal">
              <b-form-input
                  v-model="consul.body_surface"
                  @click="calculateBody"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Temperatura">
              <b-form-input
                  v-model="consul.temperature"
                  placeholder="Temperatura"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" lg="3">
            <b-form-group label="Presión arterial">
              <b-form-input
                  v-model="consul.blood_pressure"
                  placeholder="Presión arterial"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group label="Oxigenación">
              <b-form-input
                  v-model="consul.oxygenation"
                  placeholder="Oxigenación"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group label="Frecuencia Cardiaca">
              <b-form-input
                  v-model="consul.frecuency_cardiac"
                  placeholder="Frecuencia Cardiaca"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-group label="Frecuencia Respiratoria">
              <b-form-input
                  v-model="consul.frecuency_respiratory"
                  placeholder="Frecuencia Respiratoria"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Perimetro Abdominal (cm)">
              <b-form-input
                  v-model="consul.abdominal_perimeter"
                  placeholder="Perimetro Abdominal (cm)"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Exploracion Fisica</h4>
            <hr style="width: 100%"/>
            <b-row>
              <b-col cols="12">
                <b-form-textarea
                    id="textarea-default1"
                    v-model="consul.physical_exploration"
                    placeholder="Exploracion Fisica"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col> -->
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Resultado estudios</h4>
            <hr style="width: 100%"/>
            <b-form-textarea
                id="textarea-default11"
                v-model="consul.result_studies"
                placeholder="Resultado estudios"
                rows="2"
                max-rows="999999"
            />
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%"/>
            <table>
              <tr>
                <th>Diagnostico</th>
                <th>Acciones</th>
              </tr>
              <tr v-for="(a, idx) in consul.diagnostics">
                <td>
                  <div style="position: relative">
                    <b-form-input
                        placeholder="Diagnostico"
                        v-model="a.product"
                        @keydown="searchDiagnostics(idx, 'create')"
                    />
                    <div
                        v-if="openSelectDiagnostics === idx"
                        style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                    >
                      <p
                          @click="createLineDiagnostics(idx)"
                          style="cursor: pointer; user-select: none"
                      >
                        Crear Linea
                      </p>
                      <div
                          v-if="listDiagnostics.length > 0"
                          v-for="(b, idx2) in listDiagnostics"
                      >
                        <p
                            style="cursor: pointer; user-select: none"
                            @click="selectDiagnosticsList(idx, b.id, b.name)"
                        >
                          {{ b.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <b-button
                      v-if="!a.new_line"
                      @click="addRowDiagnostics(idx, 'create')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Agregar
                  </b-button>
                  <b-button
                      v-else
                      @click="removeDiagnostics(idx, 'create')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Borrar
                  </b-button>
                </td>
              </tr>
            </table>
          </b-col>

          <!-- <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreateDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreate()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consul.recipes">
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Medicamento"
                            v-model="a.product"
                            @keydown="searchProducts(idx, 'create')"
                        />
                        <div
                            v-if="openSelect === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineProduct(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listProducts.length > 0"
                              v-for="(b, idx2) in listProducts"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectProductList(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="a.indications"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!a.new_line"
                          @click="addRowMedicines(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeLineMedicines(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col> -->

          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="8"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFStudiesCreate()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Hoja de estudios
                </b-button>
              </b-col>
            </b-row>
            <h3 style="text-align: center">Estudios y laboratorio</h3>
            <b-col cols="12">
              <b-form-checkbox
                  class="custom-control-dark"
                  name="check-button3"
                  @input="changeLab(!consul.order_lab.recommend_lab)"
                  switch
              />
            </b-col>
            <b-row v-if="consul.order_lab.recommend_lab">
              <b-col cols="12">
                <b-form-group
                    label="Nombre laboratorio"
                    label-for="h-email"
                >
                  <v-select
                      @input="change_laboratory(false)"
                      v-model="extra_select_laboratorie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="laboratories"
                  />
                </b-form-group>

              </b-col>
              <b-col cols="6">
                <b-form-group label="Dirección laboratorio">
                  <b-form-input
                      disabled
                      v-model="consul.order_lab.address"
                      placeholder="Dirección laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Telefóno laboratorio">
                  <b-form-input
                      disabled
                      v-model="consul.order_lab.phone"
                      placeholder="Telefóno laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea
                      v-model="consul.order_lab.observations"
                      placeholder="Observaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card-text class="mb-0"> Incluye Diagnostico</b-card-text>
                <b-form-checkbox
                    :checked="consul.order_lab.include_diagnostic"
                    class="custom-control-dark"
                    name="check-button5"
                    @input="
                    changeDiagnostic(!consul.order_lab.include_diagnostic)"
                    switch
                />
              </b-col>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Estudio</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(c, idx) in consul.order_lab.lines">
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Estudios"
                            v-model="c.product"
                            @keydown="searchStudies(idx, 'create')"
                        />
                        <div
                            v-if="openSelectStudies === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineStudy(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listStudiesLab.length > 0"
                              v-for="(b, idx2) in listStudiesLab"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectStudyLab(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="c.indications"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!c.new_line"
                          @click="addRowStudies(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeStudies(idx)"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-row v-else></b-row>
          </b-col>


          <!-- <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="8"></b-col>
              <b-col cols="4">
              </b-col>
            </b-row>
            <h3 style="text-align: center">Tratamiento Oncologicos</h3>
            <b-row>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Tratamiento</th>
                    <th>Anotaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(c, idx) in consul.oncologicalTretaments.oncologicalLines">
                    <td>
                      <b-form-group label="Tratamiento" label-for="h-email">
                        <v-select
                            v-model="c.treatment"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="linesTreatmentsOncological"
                        />
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Anotaciones"
                          v-model="c.description"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!c.new_line"
                          @click="addRowOncological(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeOncological(idx,'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" style="margin-top: 40px">
                <b-card-text class="mb-0"> Diagnóstico molecular</b-card-text>
                <b-form-checkbox
                    :checked="consul.oncologicalTretaments.molecular_diagnosis"
                    class="custom-control-dark"
                    name="check-button5"
                    @input="changeDiagnosticOncological(!consul.oncologicalTretaments.molecular_diagnosis, 'create')"
                    switch
                />
                <b-form-group style="margin-top: 40px"
                              label="Anotaciones"
                >
                  <b-form-textarea
                      v-model="consul.oncologicalTretaments.description"
                      placeholder="Anotaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Tratamiento" label-for="h-email">
                  <v-select
                      v-model="lineTreatment"
                      @input="changeTreatmentReason('create')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="linesTreatments"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">

                <b-form-group label="Respues de tratamiento" label-for="h-email">
                  <v-select
                      v-model="answerTreatment"
                      @input="changeAnswerReason('create')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="answerTreatments"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group style="margin-top: 40px"
                              label="Especificaciones"
                >
                  <b-form-textarea
                      v-model="consul.oncologicalTretaments.specifications"
                      placeholder="Especificaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>

            </b-row>

          </b-col> -->


          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Analisis y Plan</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%"/>
                <b-form-textarea
                    id="textarea-default113"
                    v-model="consul.analysis_and_plan"
                    placeholder="Analisis y Plan"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col>


          <!-- <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Indicaciones Extras</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%"/>
                <b-form-textarea
                    id="textarea-default1133"
                    v-model="consul.extra_indications"
                    placeholder="Indicaciones Extras"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col> -->

          
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Archivos</h3>
            <b-row class="mt-3">
              <b-col cols="9">
                <b-row class="mt-3">
                  <b-col
                      cols="12"
                      v-for="(a, idx) in ClinicConsultationsFiles"
                      :key="idx"
                  >
                    <b-form-group label="Subir Archivo" label-for="h-email">
                      <b-form-file
                          @change="handleImage(idx)"
                          :id="`file${idx}`"
                          placeholder="Subir archivo."
                          accept="application/pdf"
                          drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <div align="right">
                      <b-button
                          @click="quitFile(idx)"
                          style="width: 25%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Quitar archivo
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-button
                    @click="addInputFile"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Nuevo archivo
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button
                @click="createConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" lg="9" v-if="updateInformation">
        <h3 style="text-align: center">Consulta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input
                  readonly=""
                  v-model="consultations.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6"></b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">Dia de consulta</h5>
              <flat-pickr
                  v-model="consultations.date_consultation"
                  class="form-control"
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febreo',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%"/>
            <b-form-textarea
                id="textarea-default22"
                v-model="consultations.note_evolution"
                placeholder="Nota de Evolución"
                rows="2"
                max-rows="999999"
            />
          </b-col>
          <b-col cols="12" class="mt-2">
            <h4>Signos Vitales</h4>
            <hr style="width: 100%"/>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Altura(Mts)">
              <b-form-input
                  v-model="consultations.height"
                  @keyup="calculateImcEdit"
                  placeholder="Altura(Mts)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Peso">
              <b-form-input
                  v-model="consultations.weight"
                  @keyup="calculateImcEdit"
                  placeholder="Peso"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="IMC">
              <b-form-input
                  v-model="consultations.imc"
                  @click="calculateImcEdit"
                  placeholder="IMC"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Superficie Corporal">
              <b-form-input
                  v-model="consultations.body_surface"
                  @click="calculateImcEdit"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group label="Temperatura">
              <b-form-input
                  v-model="consultations.temperature"
                  placeholder="Temperatura"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" lg="3">
            <b-form-group label="Presión arterial">
              <b-form-input
                  v-model="consultations.blood_pressure"
                  placeholder="Presión arterial"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group label="Oxigenación">
              <b-form-input
                  v-model="consultations.oxygenation"
                  placeholder="Oxigenación"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group label="Frecuencia Cardiaca">
              <b-form-input
                  v-model="consultations.frecuency_cardiac"
                  placeholder="Frecuencia Cardiaca"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-group label="Frecuencia Respiratoria">
              <b-form-input
                  v-model="consultations.frecuency_respiratory"
                  placeholder="Frecuencia Respiratoria"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Perimetro Abdominal (cm)">
              <b-form-input
                  v-model="consultations.abdominal_perimeter"
                  placeholder="Perimetro Abdominal (cm)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="12">
                <h4>Exploracion Fisica</h4>
                <hr style="width: 100%"/>
                <b-form-textarea
                    id="textarea-default1232323"
                    v-model="consultations.physical_exploration"
                    placeholder="Exploracion Fisica"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Resultado estudios</h4>
            <hr style="width: 100%"/>
            <b-form-textarea
                id="textarea-default1123232332"
                v-model="consultations.result_studies"
                placeholder="Resultado estudios"
                rows="2"
                max-rows="999999"
            />
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%"/>
            <table>
              <tr>
                <th>Diagnostico</th>
                <th>Acciones</th>
              </tr>
              <tr v-for="(a, idx) in consultations.ClinicDiagnostics">
                <td>
                  <div style="position: relative">
                    <b-form-input
                        placeholder="Diagnostico"
                        v-model="a.product"
                        @keydown="searchDiagnostics(idx, 'edit')"
                    />
                    <div
                        v-if="openSelectDiagnostics === idx"
                        style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                    >
                      <p
                          @click="createLineDiagnosticsEdit(idx)"
                          style="cursor: pointer; user-select: none"
                      >
                        Crear Linea
                      </p>
                      <div
                          v-if="listDiagnostics.length > 0"
                          v-for="(b, idx2) in listDiagnostics"
                      >
                        <p
                            style="cursor: pointer; user-select: none"
                            @click="selectDiagnosticsListEdit(idx, b.id, b.name)"
                        >
                          {{ b.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <b-button
                      v-if="!a.new_line"
                      @click="addRowDiagnostics(idx, 'edit')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Agregar
                  </b-button>
                  <b-button
                      v-else
                      @click="removeDiagnostics(idx, 'edit')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Borrar
                  </b-button>
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipe()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consultations.ClinicRecipes">
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Medicamento"
                            v-model="a.product"
                            @keydown="searchProducts(idx, 'edit')"
                        />
                        <div
                            v-if="openSelect === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                          "
                        >
                          <p
                              @click="createLineProductEdit(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listProducts.length > 0"
                              v-for="(b, idx2) in listProducts"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectProductListEdit(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="a.indications"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!a.new_line"
                          @click="addRowMedicines(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeLineMedicines(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>

          <b-col
              v-if="consultations.ClinicOrderStudy"
              cols="12"
              class="mt-3 card-recipe"
              style="padding: 20px"
          >
            <b-row>
              <b-col cols="8"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFStudies()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Hoja de estudios
                </b-button>
              </b-col>
            </b-row>
            <h3 style="text-align: center">Estudios y laboratorio</h3>
            <b-col cols="12">
              <b-form-checkbox
                  :checked="consultations.ClinicOrderStudy.recommend_lab"
                  class="custom-control-dark"
                  @input="
                  changeLabUpdate(!consultations.ClinicOrderStudy.recommend_lab)
                "
                  switch
              />
            </b-col>

            <b-row v-if="consultations.ClinicOrderStudy.recommend_lab">
              <b-col cols="12">
                <b-form-group
                    label="Nombre laboratorio"
                    label-for="h-email"
                >
                  <v-select
                      @input="change_laboratory(true)"
                      v-model="extra_select_laboratorie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="laboratories"
                  />
                </b-form-group>

              </b-col>
              <b-col cols="6">
                <b-form-group label="Dirección laboratorio">
                  <b-form-input
                      disabled
                      v-model="consultations.ClinicOrderStudy.address"
                      placeholder="Dirección laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Telefóno laboratorio">
                  <b-form-input
                      disabled
                      v-model="consultations.ClinicOrderStudy.phone"
                      placeholder="Telefóno laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea
                      v-model="consultations.ClinicOrderStudy.observations"
                      placeholder="Observaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card-text class="mb-0"> Incluye Diagnostico</b-card-text>
                <b-form-checkbox
                    :checked="consultations.ClinicOrderStudy.include_diagnostic"
                    class="custom-control-dark"
                    name="check-button"
                    @input="
                    changeDiagnosticUpdate(
                      !consultations.ClinicOrderStudy.include_diagnostic
                    )
                  "
                    switch
                />
                <b-form-group
                    v-if="consultations.ClinicOrderStudy.include_diagnostic"
                    label="Diagnostico"
                >
                  <b-form-textarea
                      v-model="consultations.ClinicOrderStudy.diagnostic"
                      placeholder="Diagnostico"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Estudio</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr
                      v-for="(b, idx) in consultations.ClinicOrderStudy
                      .ClinicStudiesLines"
                  >
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Estudios"
                            v-model="b.product"
                            @keydown="searchStudies(idx, 'edit')"
                        />
                        <div
                            v-if="openSelectStudies === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineStudyEdit(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listStudiesLab.length > 0"
                              v-for="(b, idx2) in listStudiesLab"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectStudyLabEdit(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="b.indications"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!b.new_line"
                          @click="addRowStudies(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeStudies(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="8"></b-col>
              <b-col cols="4">
              </b-col>
            </b-row>
            <h3 style="text-align: center">Tratamiento Oncologico</h3>
            <b-row>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Tratamiento</th>
                    <th>Anotaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(c, idx) in consultations.ClinicOncologicalTreatment.ClinicOncologicalLines">
                    <td>
                      <b-form-group label="Tratamiento" label-for="h-email">
                        <v-select
                            v-model="c.treatment"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="linesTreatmentsOncological"
                        />
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Anotaciones"
                          v-model="c.description"
                      />
                    </td>
                    <td>
                      <b-button
                          v-if="!c.new_line"
                          @click="addRowOncological(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeOncological(idx,'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" style="margin-top: 40px">
                <b-card-text class="mb-0"> Diagnóstico molecular</b-card-text>
                <b-form-checkbox
                    :checked="consultations.ClinicOncologicalTreatment.molecular_diagnosis"
                    class="custom-control-dark"
                    name="check-button5"
                    @input="changeDiagnosticOncological(!consultations.ClinicOncologicalTreatment.molecular_diagnosis, 'edit')"
                    switch
                />
                <b-form-group style="margin-top: 40px"
                              label="Anotaciones"
                >
                  <b-form-textarea
                      v-model="consultations.ClinicOncologicalTreatment.description"
                      placeholder="Anotaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Tratamiento" label-for="h-email">
                  <v-select
                      v-model="lineTreatment"
                      @input="changeTreatmentReason('edit')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="linesTreatments"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">

                <b-form-group label="Respues de tratamiento" label-for="h-email">
                  <v-select
                      v-model="answerTreatment"
                      @input="changeAnswerReason('edit')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="answerTreatments"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group style="margin-top: 40px"
                              label="Especificaciones"
                >
                  <b-form-textarea
                      v-model="consultations.ClinicOncologicalTreatment.specifications"
                      placeholder="Especificaciones"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>

            </b-row>

          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Analisis y Plan</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%"/>
                <b-form-textarea
                    id="textarea-default123323213"
                    v-model="consultations.analysis_and_plan"
                    placeholder="Analisis y Plan"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Indicaciones Extras</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%"/>
                <b-form-textarea
                    id="textarea-default113"
                    v-model="consultations.extra_indications"
                    placeholder="Indicaciones Extras"
                    rows="2"
                    max-rows="999999"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Archivos</h3>
            <b-row class="mt-3">
              <b-col cols="9">
                <b-row class="mt-3">
                  <b-col
                      cols="12"
                      style="margin-top: 10px"
                      v-for="(c, idx) in ClinicConsultationsFilesEdit"
                      :key="idx"
                  >
                    <b-form-group label="Subir Archivo" label-for="h-email">
                      <b-form-file
                          @change="handleImageEdit(idx)"
                          :id="`fileEdit${idx}`"
                          placeholder="Subir archivo."
                          accept="application/pdf"
                          drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <div align="right">
                      <b-button
                          @click="quitFileEdit(idx)"
                          style="width: 25%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Quitar archivo
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-button
                    @click="editInputFile"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Nuevo archivo
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  v-if="FilesDownloadList"
                  v-for="(b, idx) in FilesDownloadList"
                  :key="idx"
                  cols="4"
                  class="mt-1"
              >
                <a
                    @click="deleteFileConsultant(b.id)"
                    style="text-align: right"
                >
                  <feather-icon
                      icon="TrashIcon"
                      style="color: darkred"
                      size="20"
                  />
                </a>
                <a @click="downloadFileOne(b.id)" style="text-align: center">
                  <feather-icon icon="FolderIcon" size="100"/>
                  <br/>
                  <span style="text-align: center">{{ b.name }} </span>
                  <br/>
                  <feather-icon
                      icon="DownloadIcon"
                      style="color: black"
                      size="20"
                  />
                  Descargar Archivo
                </a>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button
                @click="updateConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import logo from "../../assets/images/logo/logo-1.png";
import signature from "../../assets/images/logo/signature.png";
import {dateFolio, dateStringMonth, dateTodayFormat, formatHour, sumMonthsHours} from "@/utils/date";
import vSelect from "vue-select";

export default {
  name: "ConsultationsPatient",
  components: {
    vSelect,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  props: {
    title: String,
    data_patient: Object,
  },
  data() {
    return {
      lineTreatment: {id: "", value: "Seleccione un tratamiento"},
      answerTreatment: {id: "", value: "Seleccione un tipo de respuesta"},
      linesOncological: {id: "", value: "Seleccione un tratamiento"},
      openSelect: null,
      extra_select_laboratorie: {id: "", value: "Seleccione un laboratorio"},
      laboratories: [],
      linesTreatments: [],
      linesTreatmentsOncological: [],
      answerTreatments: [],
      laboratoriesAll: [],
      openSelectDiagnostics: null,
      openSelectStudies: null,
      show: false,
      listProducts: [],
      listStudiesLab: [],
      ClinicConsultationsFiles: [
        {
          file: null,
          name: null,
          position: null,
        },
      ],

      consultations: {
        id: null,
        height: null,
        weight: null,
        abdominal_perimeter: null,
        blood_pressure: null,
        temperature: null,
        body_surface: null,
        heart_rate: null,
        diagnosis: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        note_evolution: null,
        years: null,
        imc: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        analysis_and_plan: null,
        patient_id: null,
        appointee_id: null,
        date_consultation: null,
        ClinicRecipes: [],
        ClinicDiagnostics: [],
        ClinicOncologicalTreatment: {},
        ClinicConsultationsFiles: [],
        ClinicOrderStudy: {},
      },
      consults: [],
      ClinicConsultationsFilesEdit: [],
      FilesDownloadList: [],
      patient_id: null,
      totalCountFiles: null,
      createConsultations: false,
      listDiagnostics: [],
      updateInformation: false,
      consul: {
        height: null,
        abdominal_perimeter: null,
        diagnosis: null,
        weight: null,
        analysis_and_plan: null,
        years: this.data_patient.birthday
            ? this.yearsCalculate(this.data_patient.birthday)
            : null,
        body_surface: null,
        date_consultation: moment().format("YYYY-MM-DD"),
        date_consultation_hours: dateTodayFormat(),
        blood_pressure: null,
        temperature: null,
        heart_rate: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        imc: null,
        note_evolution: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        patient_id: null,
        appointee_id: null,
        study_field: null,
        study_school: null,
        visa_no: null,
        permit_no: null,
        vat: null,
        name_doctor: null,
        folio: null,
        date_validity: null,
        img_qr: null,
        recipes: [
          {
            recipe_medicines_id: null,
            product: null,
            indications: null,
            new_line: null,
          },
        ],
        ClinicConsultationsFiles: [],
        oncologicalTretaments: {
          description: null,
          line_treatment: null,
          answer_treatment: null,
          specifications: null,
          molecular_diagnosis: false,
          oncologicalLines: [
            {
              treatment: null,
              description: null,
              new_line: false,
            }
          ]
        },
        diagnostics: [
          {
            diagnostics_list_id: null,
            product: null,
            new_line: null,
          },
        ],
        order_lab: {
          name: null,
          address: null,
          phone: null,
          observations: null,
          list_laboratories_id: null,
          recommend_lab: false,
          include_diagnostic: false,
          diagnostic: null,
          lines: [
            {
              study_lab_id: null,
              product: null,
              indications: null,
              new_line: false,
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.$root.$on("chargeConsultation", async () => {
      // your code goes here
      this.patient_id = this.data_patient.id;
      this.consul.study_school = this.getUser.study_school;
      this.consul.visa_no = this.getUser.visa_no;
      this.consul.permit_no = this.getUser.permit_no;
      this.consul.vat = this.getUser.ResPartnerAuth ? this.getUser.ResPartnerAuth.vat : '';
      this.consul.name_doctor = this.getUser.name;
      this.consul.folio = this.getUser.folio;
      this.consul.study_field = this.getUser.study_field;
      this.consul.img_qr = this.getUser.img_qr;
      await this.getConsultations();
    });
  },
  methods: {
    ...mapActions("patient", [
      "findPatientAllConsultations",
      "createConsultation",
      "findPatientOneConsultations",
      "updateConsultationStore",
      "findPatientAllConsultationsDesc",
      "deleteFileRequest",
      "downloadFiConsultations",
      "findMedicinesSearch",
      "createProductMedicines",
      "generateQrRecipe",
      "findListAnswerTreatments",
      "findListLineTreatments",
      "findListTreatmentsLines",
        "generateQrStudies"
    ]),
    ...mapActions("studies", [
      "findStudiesLab",
      "createProductStudiesLab",
      "findDiagnostics",
      "createProductDiagnostics",
      "findLaboratoriesAll"
    ]),
    async listAnswerTreatments() {
      const response = await this.findListAnswerTreatments();
      this.answerTreatments = []
      for (const a of response) {
        this.answerTreatments.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async listAllTreatments() {
      const response = await this.findListTreatmentsLines();
      this.linesTreatmentsOncological = []
      for (const a of response) {
        this.linesTreatmentsOncological.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async listLineTreatments() {
      const response = await this.findListLineTreatments();
      this.linesTreatments = []
      for (const a of response) {
        this.linesTreatments.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async changeTreatmentReason(type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.line_treatment = this.lineTreatment.id;
      } else {
        this.consultations.ClinicOncologicalTreatment.line_treatment = this.lineTreatment.id;
      }

    },

    async changeAnswerReason(type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.answer_treatment = this.answerTreatment.id;
      } else {
        this.consultations.ClinicOncologicalTreatment.answer_treatment = this.answerTreatment.id;
      }

    },
    change_laboratory(edit) {
      if (!edit) {
        this.consul.order_lab.list_laboratories_id = this.extra_select_laboratorie.id;

        const findLaboratory = this.laboratoriesAll.find((a) => a.id === this.extra_select_laboratorie.id);
        if (!findLaboratory) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este laboratorio',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }

        this.consul.order_lab.name = findLaboratory.name;
        this.consul.order_lab.phone = findLaboratory.phone;
        this.consul.order_lab.address = findLaboratory.address;

      } else {
        this.consultations.medical_inter_consultations_id = this.extra_select_laboratorie.id;
        const findLaboratory = this.laboratoriesAll.find((a) => a.id === this.extra_select_laboratorie.id);
        if (!findLaboratory) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.consultations.ClinicOrderStudy.name = findLaboratory.name;
        this.consultations.ClinicOrderStudy.phone = findLaboratory.phone;
        this.consultations.ClinicOrderStudy.address = findLaboratory.address;
      }

    },
    async getAllLaboratories() {
      this.show = true;
      const response = await this.findLaboratoriesAll();

      this.laboratories = [];
      this.laboratoriesAll = [];
      for (const a of response.laboratories) {
        this.laboratories.push({
          id: a.id,
          value: a.name,
        })
      }
      this.laboratoriesAll = response.laboratories;
      this.show = false;

    },
    async selectDiagnosticsList(idx, id, name) {
      this.consul.diagnostics[idx].diagnostics_list_id = id;
      this.consul.diagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },

    async selectDiagnosticsListEdit(idx, id, name) {
      this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = id;
      this.consultations.ClinicDiagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },
    async createLineDiagnostics(idx) {
      if (!this.consul.diagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.openSelectDiagnostics = null;
      this.show = true;
      const send = {
        name: this.consul.diagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consul.diagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineDiagnosticsEdit(idx) {
      if (!this.consultations.ClinicDiagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      this.openSelectDiagnostics = null;
      const send = {
        name: this.consultations.ClinicDiagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchDiagnostics(idx, type) {
      if (type === "create") {
        if (this.consul.diagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consul.diagnostics[idx].product
          );

          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }

        } else {
          this.openSelectDiagnostics = null;
        }
      } else {
        if (this.consultations.ClinicDiagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consultations.ClinicDiagnostics[idx].product
          );
          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectDiagnostics = null;
        }
      }
    },
    async downloadFileOne(id) {
      await this.downloadFiConsultations(id);
    },
    async selectProductList(idx, id, name) {
      this.consul.recipes[idx].recipe_medicines_id = id;
      this.consul.recipes[idx].product = name;
      this.openSelect = null;
    },
    async selectStudyLab(idx, id, name) {
      this.consul.order_lab.lines[idx].study_lab_id = id;
      this.consul.order_lab.lines[idx].product = name;
      this.openSelectStudies = null;
    },

    async selectStudyLabEdit(idx, id, name) {
      this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].study_lab_id = id;
      this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product = name;
      this.openSelectStudies = null;
    },
    async selectProductListEdit(idx, id, name) {
      this.consultations.ClinicRecipes[idx].recipe_medicines_id = id;
      this.consultations.ClinicRecipes[idx].product = name;
      this.openSelect = null;
    },

    async createLineStudy(idx) {
      if (!this.consul.order_lab.lines[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.order_lab.lines[idx].product,
      };
      const response = await this.createProductStudiesLab(send);
      if (!response.error) {
        this.show = false;
        this.consul.order_lab.lines[idx].study_lab_id = response;
        this.openSelectStudies = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineStudyEdit(idx) {
      if (!this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product,
      };
      const response = await this.createProductStudiesLab(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].study_lab_id = response;
        this.openSelectStudies = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProduct(idx) {
      if (!this.consul.recipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.recipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consul.recipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProductEdit(idx) {
      if (!this.consultations.ClinicRecipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicRecipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicRecipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchProducts(idx, type) {
      if (type === "create") {
        if (this.consul.recipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consul.recipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      } else {
        if (this.consultations.ClinicRecipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consultations.ClinicRecipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      }
    },
    async searchStudies(idx, type) {
      if (type === "create") {
        if (this.consul.order_lab.lines[idx].product) {
          this.openSelectStudies = idx;
          const response = await this.findStudiesLab(
              this.consul.order_lab.lines[idx].product
          );
          this.listStudiesLab = [];
          if (response.studies.length > 0) {
            for (const a of response.studies) {
              this.listStudiesLab.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectStudies = null;
        }
      } else {
        if (this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product) {
          this.openSelectStudies = idx;
          const response = await this.findStudiesLab(
              this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product
          );
          this.listStudiesLab = [];
          if (response.studies.length > 0) {
            for (const a of response.studies) {
              this.listStudiesLab.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectStudies = null;
        }
      }
    },
    async getConsultations() {
      this.consults = await this.findPatientAllConsultations(this.patient_id);
      const responseDesc = await this.findPatientAllConsultationsDesc(
          this.patient_id
      );
      this.consul.diagnosis = responseDesc ? responseDesc.diagnosis : null;
    },
    calculateImc() {
      if (!this.consul.height) return;
      if (!this.consul.weight) return;
      const multi = this.consul.height * this.consul.height;
      const total = this.consul.weight / multi;
      this.consul.imc = total.toFixed(2);
      const cm = this.consul.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consul.body_surface = total_body.toFixed(2);
    },
    calculateImcEdit() {
      if (!this.consultations.height) return;
      if (!this.consultations.weight) return;
      const multi = this.consultations.height * this.consultations.height;
      const total = this.consultations.weight / multi;
      this.consultations.imc = total.toFixed(2);
      const cm = this.consultations.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consultations.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consultations.body_surface = total_body.toFixed(2);
    },
    calculateBody() {
      if (!this.consul.height) return;
      if (!this.consul.weight) return;
      const multi = this.consul.height * this.consul.height;
      const total = this.consul.weight / multi;
      this.consul.imc = total.toFixed(2);
      const cm = this.consul.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consul.body_surface = total_body.toFixed(2);
    },
    exportPDFRecipeCreate() {
      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(`${this.consul.date_consultation}`, 4, 3);
            doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(`${this.dateStringEs(this.consul.date_consultation)}`, 4, 3);
      doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consul.recipes,
      });

      doc.save(`${a}-${b}.pdf`);
    },

    exportPDFRecipe() {
      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(
                `${this.dateStringEs(this.consultations.date_consultation)}`,
                4,
                3
            );
            doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);

            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(
          `${this.dateStringEs(this.consultations.date_consultation)}`,
          4,
          3
      );
      doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);

      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consultations.ClinicRecipes,
      });

      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFRecipeDigital() {
      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            const img = new Image();
            img.src = logo;
            doc.addImage(img, "png", 8, 1, 4, 2);
            doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
            doc.text(
                `Dia de consulta: ${this.consultations.date_consultation}`,
                1,
                4.5
            );
            doc.text(`Edad: ${this.consultations.years} Años`, 1, 5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 6,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consultations.ClinicRecipes,
            });

            if (this.data_patient.ResPartnerAppointment.HrEmployee) {
              const img2 = new Image();
              img2.src =
                  this.data_patient.ResPartnerAppointment.HrEmployee.signature;
              doc.addImage(img2, "png", 8, 12, 4, 2);
            }
            doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consultations.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consultations.date_consultation_hours ? this.consultations.date_consultation_hours : dateTodayFormat())}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${this.consultations.date_consultation_hours ? sumMonthsHours(this.consultations.date_consultation_hours, 1) : sumMonthsHours(dateTodayFormat(), 1)}`,
          12,
          2.5
      );
      doc.text(`Folio: ${this.consultations.folio}`, 6, 3.3);
      doc.text(`${this.consultations.name_doctor}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consultations.vat ? this.consultations.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consultations.study_field ? this.consultations.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consultations.permit_no ? this.consultations.permit_no : ''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consultations.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }


      const strArr = doc.splitTextToSize(this.consultations.diagnosis ? this.consultations.diagnosis : "", 95)


      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 9,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consultations.ClinicRecipes,
      });
      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {
            const img2 = new Image();
            img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature;
            doc.addImage(img2, "png", 8, 12, 4, 2);
          }

        }

      }
      const send = {
        folio: this.consultations.folio ? this.consultations.folio : "---",
        date_consultation: this.consultations.date_consultation ? this.consultations.date_consultation : "---",
        name_doctor: this.consultations.name_doctor ? this.consultations.name_doctor : "---",
        permit_no: this.consultations.permit_no ? this.consultations.permit_no : "---",
        vat: this.consultations.vat ? this.consultations.vat : "---",
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(`${this.consultations.name_doctor}`, 8, 17);
      doc.text(`Beethoven 287, Juan Manuel, 44680 Guadalajara, Jal.`, 6.5, 23);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, 17.5, 4, 4);
      doc.save(`${a}-${b}.pdf`);
    },

    async exportPDFRecipeCreateDigital() {
      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(10);
            const img = new Image();
            img.src = logo;
            doc.addImage(img, "png", 8, 1, 4, 2);
            doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
            doc.text(
                `Dia de consulta: ${formatHour(this.consul.date_consultation_hours)}`,
                1,
                4.5
            );
            doc.text(`Edad: ${this.consul.years} Años`, 1, 5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 6,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            });

            if (this.data_patient.ResPartnerAppointment.HrEmployee) {
              const img2 = new Image();
              img2.src =
                  this.data_patient.ResPartnerAppointment.HrEmployee.signature;
              doc.addImage(img2, "png", 8, 12, 4, 2);
            }
            doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consul.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consul.date_consultation_hours, 1)}`,
          12.5,
          2.5
      );
      this.consul.folio = dateFolio(this.data_patient.id)
      doc.text(`Folio: ${this.consul.folio}`, 6, 3.3);
      doc.text(`${this.consul.name_doctor}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consul.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }


      const strArr = doc.splitTextToSize(this.consul.diagnosis ? this.consul.diagnosis : "", 95)


      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 9,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consul.recipes,
      });
      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {
            const img2 = new Image();
            img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature;
            doc.addImage(img2, "png", 8, 12, 4, 2);
          }

        }

      }
      const send = {
        folio: this.consul.folio ? this.consul.folio : '----',
        date_consultation: this.consul.date_consultation ? this.consul.date_consultation : '----',
        name_doctor: this.consul.name_doctor ? this.consul.name_doctor : '----',
        permit_no: this.consul.permit_no ? this.consul.permit_no : '----',
        vat: this.consul.vat ? this.consul.vat : '----',
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(`${this.consul.name_doctor}`, 8, 17);
      doc.text(`Beethoven 287, Juan Manuel, 44680 Guadalajara, Jal.`, 6.5, 23);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, 17.5, 4, 4);
      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFStudiesCreate() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Estudios de laboratorio`, 225, 50);
      doc.setFontSize(13);
      doc.text(
          `Fecha: ${formatHour(this.consul.date_consultation_hours)}`,
          400,
          50
      );
      doc.text(`${this.consul.name_doctor ? this.consul.name_doctor : ""}`, 40, 120);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          340,
          120
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 40, 140);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''}`, 40, 165);
      doc.text(`Nombre Paciente:`, 40, 190);
      doc.text(`${this.data_patient.name}`, 40, 205);

      if (this.consul.years) {
        doc.text(`Edad:`, 340, 190);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 340, 205);
      } else {
        doc.text(`Edad:`, 340, 190);
      }
      doc.text(`Laboratorio: ${this.consul.order_lab.name ? this.consul.order_lab.name : ""}`, 40, 235);
      doc.text(`Dirección: ${this.consul.order_lab.address ? this.consul.order_lab.address : ""}`, 40, 255);
      doc.text(`Telefono: ${this.consul.order_lab.phone ? this.consul.order_lab.phone : ""}`, 40, 275);
      doc.text(`Observaciones: ${this.consul.order_lab.observations ? this.consul.order_lab.observations : ""}`, 40, 295);
      doc.autoTable({
        columns: [
          {header: "Estudio", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        body: this.consul.order_lab.lines,
        margin: {top: 320},
      });
      const send = {
        date_consultation: this.consul.date_consultation ? this.consul.date_consultation : "---",
        name_doctor: this.consul.name_doctor ? this.consul.name_doctor : "---",
        permit_no: this.consul.permit_no ? this.consul.permit_no : "---",
        vat: this.consul.vat ? this.consul.vat : "---",
      }
      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, 600, 120, 120);
      doc.save(
          `${this.data_patient.name}-${this.consul.date_consultation}.pdf`
      );
    },

   async exportPDFStudies() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Estudios de Laboratorio`, 225, 50);
      doc.setFontSize(13);
      doc.text(
          `Fecha: ${this.consultations.date_consultation_hours ? formatHour(this.consultations.date_consultation_hours) : dateStringMonth()}`,
          400,
          50
      );
      doc.text(`${this.consultations.name_doctor ? this.consultations.name_doctor : ""}`, 40, 120);
      doc.text(
          `RFC: ${
              this.consultations.vat ? this.consultations.vat : ""
          }`,
          340,
          120
      );
      doc.text(`Especialidad: ${this.consultations.study_field ? this.consultations.study_field : ''}`, 40, 140);
      doc.text(`Cedula: ${this.consultations.permit_no ? this.consultations.permit_no : ''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''}`, 40, 165);
      doc.text(`Nombre Paciente:`, 40, 190);
      doc.text(`${this.data_patient.name}`, 40, 205);

      if (this.consul.years) {
        doc.text(`Edad:`, 340, 190);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 340, 205);
      } else {
        doc.text(`Edad:`, 340, 190);
      }
      doc.text(`Laboratorio: ${this.consultations.ClinicOrderStudy.name ? this.consultations.ClinicOrderStudy.name : ""}`, 40, 235);
      doc.text(`Dirección: ${this.consultations.ClinicOrderStudy.address ? this.consultations.ClinicOrderStudy.address : ""}`, 40, 255);
      doc.text(`Telefono: ${this.consultations.ClinicOrderStudy.phone ? this.consultations.ClinicOrderStudy.phone : ""}`, 40, 275);
      doc.text(`Observaciones: ${this.consultations.ClinicOrderStudy.observations ? this.consultations.ClinicOrderStudy.observations : ""}`, 40, 295);
      doc.autoTable({
        columns: [
          {header: "Estudio", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        body: this.consultations.ClinicOrderStudy.ClinicStudiesLines,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        margin: {top: 320},
      });
      const send = {
        date_consultation: this.consultations.date_consultation ? this.consultations.date_consultation : "---",
        name_doctor: this.consultations.name_doctor ? this.consultations.name_doctor : "---",
        permit_no: this.consultations.permit_no ? this.consultations.permit_no : "---",
        vat: this.consultations.vat ? this.consultations.vat : "---",
      }
      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, 600, 120, 120);
      doc.save(
          `${this.data_patient.name}-${this.consultations.date_consultation ? this.consultations.date_consultation : dateStringMonth()}.pdf`
      );
    },
    async newConsultations() {
      this.consultations.ClinicOrderStudy.recommend_lab = false;
      this.createConsultations = true;
      this.updateInformation = false;
      this.consul.order_lab.recommend_lab = false;
      await this.getAllLaboratories();
      await this.listAnswerTreatments();
      await this.listLineTreatments();
      await this.listAllTreatments();
    },
    changeDiagnostic(state) {
      this.consul.order_lab.include_diagnostic = state;
    },
    changeDiagnosticOncological(state, type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.molecular_diagnosis = state;
      } else {
        this.consultations.ClinicOncologicalTreatment.molecular_diagnosis = state;
      }

    },
    changeDiagnosticUpdate(state) {
      this.consultations.ClinicOrderStudy.include_diagnostic = state;
    },
    async updateConsult() {
      this.show = true;
      if (this.ClinicConsultationsFilesEdit.length > 0) {
        for (const a of this.ClinicConsultationsFilesEdit) {
          if (a.name && a.file) {
            this.consultations.ClinicConsultationsFiles.push({
              name: a.name,
              file: a.file,
              clinic_consultations_id: this.consultations.id,
            });
          }
        }
      } else {
        this.consultations.ClinicConsultationsFiles = [];
      }

      const response = await this.updateConsultationStore(this.consultations);

      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se edito correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        await this.viewConsultation(this.consultations.id);
        this.createConsultations = false;
        this.show = false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async deleteFileConsultant(id) {
      this.show = true;
      const response = await this.deleteFileRequest(id);
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se Borro el archivo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.show = false;
        await this.viewConsultation(this.consultations.id);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al borrar el archivo`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async viewConsultation(id) {
      await this.listAnswerTreatments();
      await this.listLineTreatments();
      await this.listAllTreatments();
      this.extra_select_laboratorie = {id: "", value: "Seleccione un laboratorio"},
          await this.getAllLaboratories();
      this.consultations = {};
      this.updateInformation = false;
      this.createConsultations = false;
      this.consultations = await this.findPatientOneConsultations(id);
      let recipes = this.consultations.ClinicRecipes;
      let diagnostics = this.consultations.ClinicDiagnostics;
      let lines = this.consultations.ClinicOrderStudy.ClinicStudiesLines;
      let consultationsLines = this.consultations.ClinicConsultationsFiles;
      let oncologicalLinesGet;
      if (this.consultations.ClinicOncologicalTreatment){
        oncologicalLinesGet = this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines;
      }else{
        oncologicalLinesGet = []
      }


      this.FilesDownloadList = [];
      this.consultations.ClinicRecipes = [];
      this.consultations.ClinicDiagnostics = [];
      if (this.consultations.ClinicOncologicalTreatment){
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines = [];
      }else{
        const addLines = {
          ClinicOncologicalLines:[]
        }
        this.consultations.ClinicOncologicalTreatment = addLines
      }

      this.consultations.ClinicConsultationsFiles = [];
      this.consultations.ClinicOrderStudy.ClinicStudiesLines = [];

      if (consultationsLines.length > 0) {
        this.ClinicConsultationsFilesEdit = [];
        const countSe = consultationsLines.length;
        this.totalCountFiles = countSe;
        const resNum = 1;
        for (const g of consultationsLines) {
          this.FilesDownloadList.push({
            name: g.name,
            id: g.id,
          });
        }
        for (var i = 0; i < resNum; i++) {
          this.ClinicConsultationsFilesEdit.push({
            name: null,
            file: null,
            position: null,
          });
        }
      } else {
        this.ClinicConsultationsFilesEdit.push({
          name: null,
          file: null,
          position: null,
        });
      }

      for (const a of recipes) {
        this.consultations.ClinicRecipes.push({
          product: a.product,
          indications: a.indications,
          recipe_medicines_id: a.recipe_medicines_id,
          clinic_consultations_id: a.clinic_consultations_id,
          new_line: true,
        });
      }
      this.consultations.ClinicRecipes.push({
        product: null,
        indications: null,
        clinic_consultations_id: this.consultations.id,
        new_line: false,
      });
      if (diagnostics.length > 0) {
        for (const a of diagnostics) {
          this.consultations.ClinicDiagnostics.push({
            product: a.name,
            diagnostics_list_id: a.diagnostics_list_id,
            clinic_consultations_id: a.clinic_consultations_id,
            new_line: true,
          });
        }

      }
      this.consultations.ClinicDiagnostics.push({
        product: null,
        diagnostics_list_id: null,
        clinic_consultations_id: null,
        new_line: false,
      });
      if (oncologicalLinesGet.length > 0) {
        for (const a of oncologicalLinesGet) {
          this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
            treatment: a.treatment,
            description: a.description,
            clinic_oncological_treatment_id: a.clinic_oncolo,
            new_line: true,
          });
        }

      }
      this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
        treatment: null,
        description: null,
        clinic_oncological_treatment_id: this.consultations.ClinicOncologicalTreatment.id,
        new_line: false,
      });
      for (const c of lines) {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: c.product,
          indications: c.indications,
          clinic_order_studies_id: c.clinic_order_studies_id,
          study_lab_id: c.study_lab_id,
          new_line: true,
        });
      }
      this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
        product: null,
        indications: null,
        study_lab_id: null,
        clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
        new_line: false,
      });
      if (this.consultations.ClinicOrderStudy.list_laboratories_id) {
        const verifyLaboratories = await this.laboratoriesAll.find((a) => a.id === this.consultations.ClinicOrderStudy.list_laboratories_id);
        this.extra_select_laboratorie.value = verifyLaboratories.name;
        this.extra_select_laboratorie.id = verifyLaboratories.id;

      }
      if (this.consultations.ClinicOncologicalTreatment.line_treatment) {
        const verifyLines = await this.linesTreatments.find((a) => a.id === parseInt(this.consultations.ClinicOncologicalTreatment.line_treatment));

        this.lineTreatment.value = verifyLines.value;
        this.lineTreatment.id = verifyLines.id;

      }
      if (this.consultations.ClinicOncologicalTreatment.answer_treatment) {
        const verifyAnswers = await this.answerTreatments.find((a) => a.id === this.consultations.ClinicOncologicalTreatment.answer_treatment);
        this.answerTreatment.value = verifyAnswers.value;
        this.answerTreatment.id = verifyAnswers.id;

      }

      this.updateInformation = true;
    },
    changeLab(state) {
      this.consul.order_lab.recommend_lab = state;
    },
    changeLabUpdate(state) {
      this.consultations.ClinicOrderStudy.recommend_lab = state;
    },
    async createConsult() {
      this.show = true;
      this.consul.ClinicConsultationsFiles = [];
      if (this.ClinicConsultationsFiles.length > 0) {
        for (const a of this.ClinicConsultationsFiles) {
          if (a.name && a.file) {
            this.consul.ClinicConsultationsFiles.push({
              name: a.name,
              file: a.file,
            });
          }
        }
      } else {
        this.consul.ClinicConsultationsFiles = [];
      }
      this.consul.folio = dateFolio(this.data_patient.id);

      this.consul.appointee_id = this.data_patient.appointee_id;
      this.consul.patient_id = this.data_patient.id;
      const response = await this.createConsultation(this.consul);
      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        this.show = false;
        this.createConsultations = false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },

    addRowMedicines(idx, type) {
      if (type === "create") {
        this.consul.recipes[idx].new_line = true;
        this.consul.recipes.push({
          product: null,
          indications: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicRecipes[idx].new_line = true;
        this.consultations.ClinicRecipes.push({
          product: null,
          indications: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeLineMedicines(id, type) {
      if (type === "create") {
        this.consul.recipes.splice(id, 1);
      } else {
        this.consultations.ClinicRecipes.splice(id, 1);
      }
    },
    addRowOncological(idx, type) {
      if (type === "create") {
        this.consul.oncologicalTretaments.oncologicalLines[idx].new_line = true;
        this.consul.oncologicalTretaments.oncologicalLines.push({
          treatment: null,
          description: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines[
            idx
            ].new_line = true;
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
          treatment: null,
          description: null,
          clinic_oncological_treatment_id: this.consultations.ClinicOncologicalTreatment.id,
          new_line: false,
        });
      }
    },
    removeOncological(id, type) {
      if (type === "create") {
        this.consul.oncologicalTretaments.oncologicalLines.splice(id, 1);
      } else {
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.splice(id, 1);
      }
    },
    addRowStudies(idx, type) {
      if (type === "create") {
        this.consul.order_lab.lines[idx].new_line = true;
        this.consul.order_lab.lines.push({
          study_lab_id: null,
          product: null,
          indications: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines[
            idx
            ].new_line = true;
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: null,
          indications: null,
          clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
          new_line: false,
        });
      }
    },
    removeStudies(id, type) {
      if (type === "create") {
        this.consul.order_lab.lines.splice(id, 1);
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.splice(id, 1);
      }
    },
    addRowDiagnostics(idx, type) {
      if (type === "create") {
        this.consul.diagnostics[idx].new_line = true;
        this.consul.diagnostics.push({
          diagnostics_list_id: null,
          product: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicDiagnostics[idx].new_line = true;
        this.consultations.ClinicDiagnostics.push({
          product: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeDiagnostics(id, type) {
      if (type === "create") {
        this.consul.diagnostics.splice(id, 1);
      } else {
        this.consultations.ClinicDiagnostics.splice(id, 1);
      }
    },
    async handleImage(id) {
      const file = document.querySelector(`#file${id}`).files[0];
      this.ClinicConsultationsFiles[id].name = file.name;
      const file_convert = await this.getBase64(file);
      file_convert.onload = (event) => {
        this.ClinicConsultationsFiles[id].file = event.target.result;
        this.ClinicConsultationsFiles[id].position = id;
      };
    },
    async handleImageEdit(id) {
      const file = document.querySelector(`#fileEdit${id}`).files[0];
      this.ClinicConsultationsFilesEdit[id].name = file.name;
      const file_convert = await this.getBase64(file);
      file_convert.onload = (event) => {
        this.ClinicConsultationsFilesEdit[id].file = event.target.result;
        this.ClinicConsultationsFilesEdit[id].position = id;
      };
    },
    async quitFile(id) {
      this.ClinicConsultationsFiles.splice(id, 1);
    },
    async quitFileEdit(id) {
      this.ClinicConsultationsFilesEdit.splice(id, 1);
    },
    async addInputFile() {
      if (this.ClinicConsultationsFiles.length > 4) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Solo puede subir maximo 5 archivos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.ClinicConsultationsFiles.push({
        name: null,
        file: null,
        position: null,
      });
    },
    async editInputFile() {
      const totalRows =
          this.totalCountFiles + this.ClinicConsultationsFilesEdit.length;
      if (totalRows > 4) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Solo puede subir maximo 5 archivos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.ClinicConsultationsFilesEdit.push({
        name: null,
        file: null,
        position: null,
      });
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}
</style>
